<template>
  <transition-group
    name="flip-list"
    mode="in-out"
    class="d-flex justify-center align-center"
  >
    <v-col
      cols="auto"
      class="py-0"
      v-for="l in availableLanguages"
      :key="l.locale"
    >
      <v-avatar
        fab
        :size="`${size}rem`"
        class="my-1 shadow languageSelector"
        color="transparent"
        link
        @click="language = l"
      >
        <v-icon>flag-icon-fab flag-icon-{{ l.code }}</v-icon>
      </v-avatar>
    </v-col>
  </transition-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    ...mapGetters("business", ["infos"]),
    ...mapGetters("system", ["language"]),
    availableLanguages() {
      if (this.infos.selectedLanguages) {
        let sortedLanguages = this.infos.selectedLanguages;
        return sortedLanguages.sort((l) => {
          if (l.locale === this.$i18n.locale) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return [];
    },
    language: {
      get() {
        return this.language;
      },
      set(language) {
        this.$store.commit("system/setLanguage", language);
        setTimeout(() => this.$emit("selected"), 150);
      },
    },
  },
  methods: {
    isMenuOnly(l) {
      if (l.code === "se" || l.code === "fr") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow {
  display: table !important;
  padding: 2px;
  box-shadow: -1px -1px 2px hsla(0, 0%, 100%, 0.3),
    1px 1px 2px rgba(0, 0, 0, 0.3), inset -1px -1px 2px rgba(0, 0, 0, 0.3),
    inset 1px 1px 2px hsla(0, 0%, 100%, 0.3);
  transition: 1s ease;
}
.shadow:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.flag-icon-fab {
  background-size: cover !important;
  background-position: 50% !important;
}

.flip-list-move {
  transition: transform 1s;
}
</style>
