<template>
  <v-container fluid>
    <v-row
      v-if="infos.businessContactInfo"
      align="center"
      justify="center"
      class="contact-header"
    >
      <v-col
        cols="auto"
        class="py-0"
        v-for="(item, i) in contactInfoWithText"
        :key="i"
      >
        <a :href="item.url" target="blank">
          <v-icon small left color="primary">{{
            item.contactType.icon
          }}</v-icon>
          {{ item.text }}
        </a>
      </v-col>
      <v-col class="d-none d-md-flex"><v-spacer></v-spacer></v-col>
      <v-col
        cols="auto"
        v-if="!$vuetify.breakpoint.mobile"
        class="pa-0 d-none d-md-flex"
      >
        <BasicLanguageSwitch :size="2" />
      </v-col>
      <v-col cols="auto" class="py-0">
        <v-row class="py-0"
          ><v-col
            class="pa-0"
            v-for="(item, i) in contactInfoWithoutText"
            :key="`ci-${i}`"
          >
            <v-btn class="button" icon smal :href="item.url" target="blank">
              <v-icon small color="primary">{{ item.contactType.icon }}</v-icon>
            </v-btn>
          </v-col></v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BasicLanguageSwitch from "@/components/system/BasicLanguageSwitch.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BasicLanguageSwitch
  },
  computed: {
    ...mapGetters("business", ["infos"]),
    contactInfoWithText() {
      return this.infos.businessContactInfo.filter(t => t.contactType.id <= 2);
    },
    contactInfoWithoutText() {
      return this.infos.businessContactInfo.filter(t => t.contactType.id > 2);
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: black !important;
}
.container {
  font-size: 0.7em;
  position: relative;
  z-index: 100;
  background-color: white;
  display: flex;
  height: auto;
}
.contact-header {
  padding: 1rem 1rem;
  width: 100%;
}
</style>
