<template>
  <transition name="carousel" mode="out-in">
    <v-container
      v-scroll="onScroll"
      v-if="image"
      fluid
      id="carousel"
      class="carousel pa-0"
      :style="`background-image: url(${image});opacity:${opacity};filter: blur(${blur}px);background-size: cover;background-position: center center;`">
      <div class="title-wrapper">
        <transition name="title" mode="out-in">
          <h1 :key="title">{{ $t(title) }}</h1>
        </transition>
        <transition name="subtitle" mode="out-in">
          <h2 :key="subtitle">{{ $t(subtitle) }}</h2>
        </transition>
      </div>
    </v-container>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("business", ["images"]),
    ...mapGetters("carousel", ["title", "subtitle", "image"]),
  },
  data() {
    return {
      visible: false,
      opacity: 1,
      blur: 0,
    };
  },
  methods: {
    onScroll() {
      let vertPerc = parseFloat(
        this.getVerticalScrollPercentage(document.body)
      );
      vertPerc /= 100;
      vertPerc = 1 - vertPerc;
      vertPerc = vertPerc < 0 ? 0 : parseFloat(vertPerc.toFixed(2));
      if (vertPerc <= 0) {
        return;
      }
      this.opacity = vertPerc < 0.8 ? 0.8 : vertPerc;
      this.blur = 10 - vertPerc * 10;
    },
    getVerticalScrollPercentage(elm) {
      let p = elm.parentNode,
        pos =
          ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) *
          100;

      return pos;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  border: solid;
  padding: 10vw 15vw;
  background: #ffffff1c;
  border-radius: 0% 38%;
  border-width: 1px;
}
.title-enter-active,
.title-leave-active {
  transition: all 0.5s ease-in;
}
.subtitle-enter-active,
.subtitle-leave-active {
  transition: all 0.5s ease-in 0.1s;
}

.title-enter,
.subtitle-enter {
  transform: translateY(0.8em) skewY(20deg) translateX(0.1em) !important;
  opacity: 0 !important;
}
.title-leave-to,
.subtitle-leave-to {
  transform: translateY(-0.8em) skewY(-20deg) translateX(-0.1em) !important;
  opacity: 0 !important;
}

.carousel {
  transform: scale(1);
  position: fixed;
  background-position: center center;
  top: 0;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  div {
    z-index: 10;
    color: white;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -80%);
      path {
        @keyframes stroke-dash {
          0% {
            stroke-dashoffset: 0;
          }
          100% {
            stroke-dashoffset: 60;
          }
        }
        stroke-dasharray: 30;
        animation: stroke-dash 3s linear 0s infinite reverse;
      }
    }
    h1 {
      opacity: 1;
      transform: translateY(0em) skew(1deg) translateX(0em);
      font-size: 4.5rem;
      text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.7);
      text-transform: uppercase;
      font-family: "Dancing Script";
      text-align: center;
      line-height: 1.6;
    }
    h2 {
      opacity: 1;
      font-size: 1.8rem;
      transform: translateY(0em) skew(-17deg) translateX(0em);
      color: var(--v-primary-base);
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      text-align: right;
      font-family: "Raleway", sans-serif;
    }
  }
}

.carousel::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    white 5%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(126, 126, 126, 0.5) 30%,
    rgba(134, 134, 134, 0)
  );
}
</style>
