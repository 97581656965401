<template>
  <div id="app-bar" class="app-bar" :class="{ 'white-app-bar': forceAppBar }">
    <div class="app-bar--item">
      <router-link to="/">
        <v-img
          id="header-image"
          v-if="infos.imageUrl"
          contain
          class="logo-shadow"
          position="left"
          width="10rem"
          height="auto"
          :src="logo"
        ></v-img>
      </router-link>
    </div>

    <div
      v-if="$vuetify.breakpoint.mobile"
      @click="toggleMenu()"
      eager
      class="app-bar--item menu-btn"
      :class="{ 'menu-btn__checked': menuDialog }"
    >
      <div class="menu-btn__burger"></div>
    </div>
    <div v-else>
      <header ref="defaultHeader" class="default-header app-bar--item mr-10">
        <v-row justify="end">
          <v-col
            cols="auto"
            v-for="(page, index) in headers"
            :key="`pages-link-${index}`"
          >
            <router-link
              :to="page.link"
              :exact="page.exact"
              active-class="active-page"
            >
              {{ $t(page.name) }}
            </router-link>
          </v-col>
        </v-row>
      </header>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleHeader);
    });
  },
  destroyed() {
    console.log(window.removeEventListener("scroll", this.handleHeader));
  },
  computed: {
    ...mapGetters("business", ["infos"]),
    ...mapGetters("loader", ["loading"]),
    logo() {
      return this.infos.imageUrl;
    },
  },
  props: {
    menuDialog: Boolean,
    headers: Array,
  },
  data() {
    return {
      eventListener: null,
      forceAppBar: false,
    };
  },
  methods: {
    toggleMenu() {
      this.$emit("menu", !this.menuDialog);
    },
    handleHeader() {
      if (this.$refs.defaultHeader) {
        const mustBeMobile = !this.$vuetify.breakpoint.mobile === true;
        const fooHeader = this.$refs.defaultHeader;
        const sticky = fooHeader.offsetTop;
        this.forceAppBar = mustBeMobile && window.pageYOffset > sticky;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  position: sticky;
  top: 0;
  z-index: 110;
  height: 5rem;
  width: 100%;
  background-color: transparent;
  transition: all 1s;

  .app-bar--item {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    .logo {
      left: 25px;
    }
  }

  .default-header {
    width: 80vw;
    right: 0;
    a {
      font-family: "Raleway", sans-serif;
      font-size: 2rem;
      text-decoration: none;
      color: var(--v-primary-base) !important;
      font-weight: 400;
    }
    .active-page {
      color: var(--v-primary-darken2) !important;
      font-weight: bold;
    }
  }
}
.white-app-bar {
  background-color: white;
  box-shadow: 0 10px 10px -15px black;
}

.menu-visible {
  display: flex !important;
  opacity: 1 !important;
}
.menu-btn {
  position: absolute;
  right: 15px;
  text-align: justify;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  filter: drop-shadow(-1px -0px 1px rgba(0, 0, 0, 0.5))
    drop-shadow(1px 1px 0px rgba(255, 254, 254, 0.6));
}

.menu-btn__burger {
  width: 3rem;
  height: 0.2rem;
  background: var(--v-primary-base);
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 3rem;
  height: 0.2rem;
  background: var(--v-primary-base);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(47, 144, 255, 0.2);
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-10px);
}

.menu-btn__burger::after {
  transform: translateY(10px);
}

.menu-btn.menu-btn__checked .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu-btn.menu-btn__checked .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.menu-btn__checked .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.logo-shadow {
  left: 20px;
}
</style>
