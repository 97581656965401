<template>
  <transition name="fade">
    <header class="menu" v-if="menuDialog">
      <v-container fluid class="pages-link-container pa-0 ">
        <v-list>
          <v-list-item
            class="pa-0"
            v-for="(page, index) in headers"
            :key="`pages-link-${index}`"
            @click.native="$emit('clicked')"
          >
            <v-list-item-title class=" text--center">
              <router-link
                :to="page.link"
                class="pages-link"
                exact
                active-class="active-page"
              >
                {{ $t(page.name) }}
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list class="pt-0">
          <v-list-group
            class="pa-0"
            v-for="(page, index) in menuCategories"
            :key="`pages-cat-link-${index}`"
            v-model="isMenuOpen"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-title
                class=" text--center"
                @click="emitMenuClicked(page)"
              >
                <router-link
                  :to="page.link"
                  class="pages-link"
                  active-class="active-page"
                >
                  {{ $t(page.name) }}
                </router-link>
              </v-list-item-title>
            </template>
            <v-row class="mt-3">
              <v-col
                class="py-0"
                cols="6"
                v-for="cat in page.categories"
                :key="cat.uuid"
              >
                <v-list-item class="min-height" @click="$emit('clicked')"
                  ><v-list-item-title>
                    <router-link
                      :to="`${page.link}/${cat.uuid}`"
                      class="pages-link-sub"
                      active-class="active-page-sub"
                    >
                      {{ cat.name[language.lcid] }}
                    </router-link></v-list-item-title
                  >
                </v-list-item>
              </v-col>
            </v-row>
          </v-list-group>
        </v-list>
        <BasicLanguageSwitch @selected="$emit('closeDialog')" />
      </v-container>
    </header>
  </transition>
</template>

<script>
import BasicLanguageSwitch from "@/components/system/BasicLanguageSwitch.vue";
export default {
  props: {
    headers: Array,
    menuCategories: Array,
    menuDialog: Boolean,
    language: Object
  },
  components: { BasicLanguageSwitch },
  computed: {
    isMenuOpen() {
      return this.$route.path.match("/menu.*");
    }
  },
  methods: {
    emitMenuClicked(page) {
      this.$router.push(page.link);
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: scale(1.2);
  opacity: 0;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  color: var(--v-primary-base) !important;
  flex-direction: column;
  background-color: white;
  justify-content: space-around;
  overflow-y: auto;
  scroll-behavior: smooth;
  .pages-link-container {
    height: 100%;
    position: fixed;
    justify-content: center;
    display: flex;
    flex-direction: column;
    ::v-deep .v-list-item {
      padding: 0;
    }
    .pages-link,
    .pages-link-sub {
      transition: background-color 0.4s ease-in;
      text-align: center;
      text-transform: uppercase;
      transition: color 0.4s ease-in;
      font-family: "Ogg";
      font-size: 3rem;
      color: var(--v-primary-base) !important;
      text-decoration: none;
      display: block;
      font-family: "Raleway";
      font-weight: 600;

      &:hover {
        background-color: var(--v-primary-base);
        color: white !important;
      }
    }
    .active-page,
    .active-page-sub {
      pointer-events: none;
      color: white !important;
      background-color: var(--v-primary-base);
    }
    .pages-link-sub {
      font-size: 1rem;
    }
  }
  .min-height {
    min-height: 30px;
  }
}
</style>
