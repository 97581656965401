<template>
  <div class="scrollable">
    <div class="instagram-text">
      <span>#INSTAGRAM</span>
    </div>
    <div id="instagram-scroll" style="overflow-x: scroll">
      <div
        no-gutters
        class="d-flex flex-nowrap"
        justify="center"
        align="center"
      >
        <div v-for="(item, i) in instadata" :key="i" cols="auto">
          <v-hover v-slot="{ hover }">
            <v-card
              @mouseover="myHover = false"
              @click="myHover = false"
              @mouseleave="myHover = true"
              target="_blank"
              :elevation="hover ? 12 : 1"
              :href="item.permalink"
              tile
              class="relative overflow-hidden"
            >
              <v-img
                class="instagram hover-scale"
                :height="size"
                :width="size"
                eager
                :src="item.media_url"
              >
              </v-img>
              <span class="instagram-icon"
                ><v-icon color="white">fab fa-instagram</v-icon></span
              >
            </v-card>
          </v-hover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instadata: null,
  },
  data() {
    return {
      myHover: true,
      size: 100,
      interval: null,
    };
  },
  watch: {
    instadata() {
      setTimeout(() => this.scrollInsta(), 100);
    },
  },
  methods: {
    scrollInsta() {
      if (this.interval) {
        self.clearInterval(this.interval);
      }
      const instagramContainer = document.getElementById("instagram-scroll");
      const instagramScrollWidth = instagramContainer.scrollWidth;
      let directionLeft = true;
      this.interval = self.setInterval(() => {
        if (this.myHover) {
          if (
            instagramContainer.scrollLeft !== instagramScrollWidth &&
            directionLeft
          ) {
            let width = instagramContainer.scrollLeft + instagramScrollWidth;
            instagramContainer.scrollTo(instagramContainer.scrollLeft + 1, 0);
            if (
              width ===
              instagramContainer.scrollLeft + instagramContainer.scrollWidth
            ) {
              directionLeft = false;
            }
          } else {
            instagramContainer.scrollTo(instagramContainer.scrollLeft - 1, 0);
            if (instagramContainer.scrollLeft === 0) {
              directionLeft = true;
            }
          }
        }
      }, 50);
    },
  },
};
</script>

<style scoped>
#instagram-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.hover-scale {
  transition: 0.4s;
}

.hover-scale:hover {
  transform: scale(1.2);
}
.scrollable {
  position: relative;
  display: block;
  overflow: scroll;
}
.instagram-text {
  z-index: 10;
  position: absolute;
  background-color: #f5f5f5cc;
  padding: 0.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.relative {
  position: relative;
}
.instagram-text span {
  color: var(--v-primary-base);
  font-family: "Montserrat", "PT Serif";
}
.instagram-icon {
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover-scale:hover + .instagram-icon {
  visibility: visible;
}
.instagram-icon:hover {
  visibility: visible;
}
.instagram::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--v-primary-base);
  opacity: 0.3;
}
</style>
